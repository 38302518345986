import React from "react";
import HomePage1 from "../components/v1/pages/homepage";
import HomePage2 from "../components/v2/pages/homepage";

const HomePage = (props: any) => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <HomePage1 pageContext={props.pageContext} />;
  } else {
    return <HomePage2 pageContext={props.pageContext} />;
  }
};

export default HomePage;
