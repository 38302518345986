import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Portfolio } from "../../../../models/portfolio.model";
import { GqlListResponse } from "../../../../models/gql-list-response.model";
import { RouteLinks } from "../../../../utils/route-links";
import LandingComponent from "../../homepage/landing-component/Landing";
import { MenuOverlayOptions } from "../../../../enums/menu-overlay-options";
import { PortfolioGroup } from "../../../../models/portfolio-group.model";
import { getPortfolioGroupLink } from "../../../../utils/link.helper";

interface Props {
  pageContext: any;
}

const query = graphql`
  {
    portfolios: allMarkdownRemark(
      filter: { fields: { category: { eq: "portfolios" } } }
      limit: 3
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            packages {
              location_slug
            }
          }
        }
      }
    }

    portfolioGroups: allMarkdownRemark(
      filter: { fields: { category: { eq: "portfolioGroups" } } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            cta
          }
        }
      }
    }

    metadata: site {
      siteMetadata {
        show_portfolio_types
      }
    }
  }
`;

const LandingPage: React.FC<Props> = (props: Props) => {
  const queryResult = useStaticQuery(query);

  const portfoliosRaw: GqlListResponse<Portfolio> = queryResult.portfolios;
  const portfolios = portfoliosRaw.edges;

  const portfolioGroupsRaw: GqlListResponse<PortfolioGroup> =
    queryResult.portfolioGroups;
  const portfolioGroups = portfolioGroupsRaw.edges;

  const navLinks: { text: string; route: string }[] = [];

  const showTypes: boolean =
    queryResult.metadata.siteMetadata.show_portfolio_types;

  if (showTypes) {
    portfolioGroups.map(el => {
      const {
        node: {
          frontmatter: { title, slug },
        },
      } = el;
      navLinks.push({
        text: title,
        route: getPortfolioGroupLink(slug),
      });
    });
  }

  return (
    <LandingComponent
      portfolios={portfolios}
      portfolioDesktopImages={props.pageContext.images}
      portfolioMobileImages={props.pageContext.mobile_images}
      navLinks={navLinks}
      menuSelected={MenuOverlayOptions.HOME}
      pageUrl={RouteLinks.index}
    />
  );
};

export default LandingPage;
